import React from "react";
import { Router } from "@reach/router";
// import SignupPage from "./components/pages/SignupPage";
import LoginPage from "./components/pages/LoginPage";
import AccountVerificationPage from "./components/pages/AccoutVerificationPage";
import Welcome from "./components/pages/Welcome";
import Cases from "./components/pages/Cases";
import HelpPage from "./components/pages/HelpPage";
import MyCases from "./components/pages/MyCases/";
import DashboardScreens from "./components/pages/DashboardScreens";
import AccountVerificationParent from "./components/pages/AccoutVerificationPage/AccountVerificationParent";
import NotVerifiedPage from "./components/pages/NotVerifiedPage";
import LoginPath from "./components/pages/LoginPage/LoginPath";
import Help from "./components/internal/Help";
import CaseDetails from "./components/pages/CaseDetails";
// import Uploads from "./components/internal/Uploads";
import NotFoundPage from "./components/pages/NotFoundPage";
import ProfileParent from "./components/pages/MyProfile/components/Parent";
import MyProfile from "./components/pages/MyProfile";
// import ChangePassword from "./components/pages/ChangePassword";
import MailRedirection from "./components/pages/MailRedirection";
import TimeSlideTranscription from "./components/pages/TimeSlideTranscription/index";
import LiveTranscription from "./components/internal/TimeSlideTranscript/LiveTranscription";
// import OrderDictation from "./components/pages/CommonDictation/index";
import CrossExamination from "./components/internal/CrossExamination";
import CrossExaminationTranscript from "./components/internal/CrossExamination/CrossExaminationTranscripts";

const AppRouter = () => {
  return (
    <Router>
      <NotFoundPage default />
      <NotFoundPage
        errorCode="Error Code : 500"
        errorMessage="The server encountered an internal error or misconfiguration and was unable to complete your request."
        path="/internal-error"
      />
      {/* <SignupPage path="/signup" />
      <SignupPage isGoogleSignUp path="/google-signup" /> */}
      <LoginPath path="/">
        <NotFoundPage default />
        <LoginPage path="/" />
        {/* <LoginOTP path="/otp-login" /> */}
      </LoginPath>
      {/* <ResetPassword path="reset-password/:resetToken" /> */}
      {/* <ForgotPassword path="forgot-password" /> */}
      {/* <OtpPage path="/otp" /> */}
      {/* <Createprofile path="/create-profile" /> */}
      <AccountVerificationParent path="/verify-email">
        <AccountVerificationPage path="/" />
        <AccountVerificationPage path="/:email_token" />
      </AccountVerificationParent>
      <NotVerifiedPage path="/not-verifed" />
      <DashboardScreens path="/view">
        <NotFoundPage default />
        {/* <Uploads path="/uploads" />
        <OrderDictation path="/order-dictation" /> */}
        <Welcome path="/welcome" />
        {/* demo page for components */}
        <Cases path="/cases">
          <NotFoundPage default />
          <MyCases path="/" />
          <CaseDetails path="/:id" />
          <TimeSlideTranscription path="/:id/transcription" />
          <LiveTranscription path="/:id/livetranscription" />
          <CrossExamination path="/:id/cross-examination" />
          <CrossExaminationTranscript path="/:id/cross-examination-transcript" />
        </Cases>
        <HelpPage path="/help">
          <NotFoundPage default />
          {/* Payments Routes to Go Here (Similar to parties) */}
          <Help path="/" />
        </HelpPage>
        <ProfileParent path="/profile">
          <NotFoundPage default />
          <MyProfile path="/" />
          {/* <ChangePassword path="/change-password" /> */}
        </ProfileParent>
        <HelpPage path="/help">
          <NotFoundPage default />
          {/* Payments Routes to Go Here (Similar to parties) */}
          <Help path="/" />
        </HelpPage>
      </DashboardScreens>
      <DashboardScreens path="/dashboard">
        <MailRedirection path="/cases/:id" redirectTo="case" />
        <MailRedirection path="help" redirectTo="help" />
      </DashboardScreens>
    </Router>
  );
};

export default AppRouter;
