import React from "react";
import { Header } from "../../internal/Header/Header";
import ActionBar from "../../common/ActionBar";
import {
  Container,
  ProfilePic,
  Grid,
  LabelValueContainer,
  Label,
  Value,
} from "./styles";
import useUser from "../../../hooks/useUser";
import _ from "lodash";
// import { states } from "../../../helpers/constants";
// import ProfileModal from "./components/ProfileModal";
import labels from "../../../helpers/labels.json";
import theme from "../../../assets/theme";

const keys1 = {
  name: labels["user.name"],
  email: labels["user.email"],
  mobile: labels["user.mobile"],
};

// const keys2 = {
//   qualification: labels["onboarding.qualification"],
//   experience: labels["onboarding.yearsOfExperience"],
//   languagesKnown: labels["onboarding.language"],
// };

// const keys3 = {
//   affiliatedOrg: labels["onboarding.affiliatedOrg"],
//   location: labels["onboarding.location"],
// };

// const keys4 = {
//   alternateMobileNumber: labels["onboarding.alternateMobileNumber"],
//   institutionOfMediationTraining:
//     labels["onboarding.institutionOfMediationTraining"],
//   numberOfMediationsConducted: labels["onboarding.numberOfMediationsConducted"],
// };

// function findState(val) {
//   const found = states.find((state) => state.value === val);
//   if (found) {
//     return found.label;
//   }
// }

const Index = () => {
  const { userObject } = useUser();
  // const [modal, setModal] = useState(false);

  // const actions = [
  //   {
  //     text: labels["actions.change_password"],
  //     props: { onClick: () => navigate("/view/profile/change-password") },
  //   },
  //   {
  //     text: labels["actions.edit_profile"],
  //     props: { onClick: () => setModal(true) },
  //   },
  // ];

  return (
    <Header showSidebar>
      <ActionBar />
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "80%",
            justifyContent: "center",
          }}
        >
          <ProfilePic
            alt="profile_img"
            src={
              userObject?.avatarUrl
                ? userObject.avatarUrl
                : require("../../../assets/images/userPlaceholder.svg")
            }
          />
          {userObject?.tagLine ? (
            <div style={{ marginLeft: 20, maxWidth: 500, textAlign: "center" }}>
              <Label style={{ fontFamily: theme.fonts.primaryFontBoldItalic }}>
                "{userObject?.tagLine}"
              </Label>
            </div>
          ) : null}
        </div>
        <Grid>
          {_.keys(keys1).map((key, index) => (
            <LabelValueContainer key={index + key}>
              <Label>{keys1[key]}</Label>
              <Value>{userObject[key] || "-"}</Value>
            </LabelValueContainer>
          ))}
          {/* {_.keys(keys2).map((key, index) => (
            <LabelValueContainer key={index + key}>
              <Label>{keys2[key]}</Label>
              <Value>
                {key === labels["user.state"].toLowerCase()
                  ? findState(userObject[key])
                  : userObject[key]}
              </Value>
            </LabelValueContainer>
          ))}
          {_.keys(keys3).map((key, index) => (
            <LabelValueContainer key={index + key}>
              <Label>{keys3[key]}</Label>
              <Value>
                {key === labels["user.state"].toLowerCase()
                  ? findState(userObject[key])
                  : userObject[key]}
              </Value>
            </LabelValueContainer>
          ))}
          {_.keys(keys4).map((key, index) => (
            <LabelValueContainer key={index + key}>
              <Label>{keys4[key]}</Label>
              <Value>
                {key === labels["user.state"].toLowerCase()
                  ? findState(userObject[key])
                  : userObject[key]}
              </Value>
            </LabelValueContainer>
          ))} */}
        </Grid>
        {/* <div style={{ marginTop: 30 }}>
          <LabelValueContainer key={"Specialites"}>
            <Label>{labels["onboarding.specialities"]}</Label>
            <Value>{userObject?.specialities?.join(", ")}</Value>
          </LabelValueContainer>
        </div>
        <div style={{ marginTop: 30 }}>
          <LabelValueContainer key={"LastHeldPosition"}>
            <Label>{labels["onboard.lastHeldPosition"]}</Label>
            <Value>{userObject?.lastHeldPosition}</Value>
          </LabelValueContainer>
        </div>
        <div style={{ marginTop: 30 }}>
          <LabelValueContainer key={"Specialites"}>
            <Label>{labels["onboarding.shortbio"]}</Label>
            <Value>{userObject?.shortBio}</Value>
          </LabelValueContainer>
        </div> */}
      </Container>
      {/* <ProfileModal {...{ modal, setModal }} /> */}
    </Header>
  );
};

export default Index;
