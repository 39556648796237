import useUser from "./useUser";
import { useEffect } from "react";
import { navigate, useLocation } from "@reach/router";
import { checkKeys } from "../helpers/functions";
import _ from "lodash";

const EXCLUDE_URL = ["welcome", "view"];

export default function useIfLoggedIn() {
  const { userObject } = useUser();
  const location = useLocation();

  useEffect(() => {
    if (
      !_.isEmpty(userObject) &&
      userObject.emailVerified &&
      !checkKeys(userObject) &&
      !EXCLUDE_URL.some((path) => location?.pathname?.includes(path))
    ) {
      navigate("/view/cases");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userObject]);
}
