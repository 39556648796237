import React, { useState, useEffect } from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useNotification from "../../../hooks/useNotification";
import { Tooltip } from "@material-ui/core";
import useBundle from "../../../hooks/useBundle";
import PdfViewer from "./PdfViewer";

const Index = ({ id, setRefreshDetails, refreshDetails, setSuccess }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [bundleName, setBundleName] = useState();
  const caseId = id;
  const { triggerNotify, setTriggerNotify } = useNotification();
  const { bundleOpen } = useBundle();

  useEffect(() => {
    if (triggerNotify) {
      setRefreshDetails(true);
      setTriggerNotify(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerNotify]);

  useEffect(() => {
    async function getBundleName() {
      try {
        const response_data = await CaseService.getBundleName(caseId);
        if (response_data) {
          setBundleName(response_data?.bundleDetails);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setSuccess(false);
      }
    }
    if (refreshDetails) {
      setRefreshDetails(false);
      getBundleName();
    } else if (bundleOpen) {
      getBundleName(bundleOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshDetails, bundleOpen]);

  return (
    <Table {...{ caseId, bundleName, setRefreshDetails, refreshDetails }} />
  );
};

export default Index;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Table = ({ caseId, bundleName, setRefreshDetails, refreshDetails }) => {
  return (
    <div>
      {bundleName
        ? bundleName.map((item, index) => (
          <TableWithAccordion
            key={index}
            title={item?.bundleName}
            id={item?.id}
            caseId={caseId}
            bundleDocs={item?.bundleDocuments}
            setRefreshDetails={setRefreshDetails}
            refreshDetails={refreshDetails}
            bundleCode={item?.bundleCode}
            visibilityType={item?.visibilityType}
            self={item?.self}
            pageNoFlag={item?.pageNoFlag}
            tableofContentsFlag={item?.tableofContentsFlag}
          />
        ))
        : ""}
    </div>
  );
};

function TableWithAccordion({
  id,
  title,
  bundleDocs,
  caseId,

  bundleCode,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <TableWrapper>
        <Accordion className={classes.root} expanded={expanded}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                onClick={() => setExpanded((e) => !e)}
                expanded={expanded}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <AccordionHeading onClick={() => setExpanded((e) => !e)}>
              {bundleCode ? `${bundleCode} - ${title}` : title}
            </AccordionHeading>
            {bundleDocs ? (
              <Actions>
                <PdfViewer
                  url={bundleDocs[0].url.url}
                  bundleCode={`${bundleCode} - ${title}`}
                />
              </Actions>
            ) : null}
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                paddingLeft: "25px",
                paddingBottom: "10px",
                paddingTop: "auto",
                overflow: "auto",
              }}
            >
              {bundleDocs?.length
                ? bundleDocs?.map((data, index) => (
                  <div key={index}>
                    <Tooltip
                      title={data?.url?.url.split("?")[0].split("/").pop()}
                      placement="top"
                    >
                      <H2
                        style={{
                          color: COLORS.BTN_GREEN,
                        }}
                        onClick={() => window.open(data?.url?.url)}
                      >
                        {data?.url?.url?.split("/").length > 0
                          ? data?.url?.url.split("?")[0].split("/").pop()
                          : "-"}{" "}
                      </H2>
                    </Tooltip>
                  </div>
                ))
                : ""}
            </div>
          </AccordionDetails>
        </Accordion>
      </TableWrapper>
    </>
  );
}

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 40px;
  margin-top: 15px;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
`;
export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
`;
export const AccordionHeading = styled.div`
  width: 230px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
  display: flex;
  align-items: center;
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  @media (min-width: 780px) {
    //width: 200px;
    width: auto;
    min-width: 100px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  margin: 0 auto;
  @media (min-width) {
    justify-content: space-between;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  padding: 0 15px;
  width: 100%;
  &:nth-child(2) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media ${theme?.breakpoints?.sm_up} {
    padding: 0 21px;
  }
  @media ${theme?.breakpoints?.lg_up} {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 6px 0 7px 0;
    padding: 0 21px 0 21px;
    &:nth-child(2) {
      margin-top: 9px;
    }
  }
`;

export const CheckedIconContainer = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DeleteActions = styled.div`
  display: flex;
  margin-left: 18px;
  align-items: center;
  cursor: pointer;
  & div {
    margin-left: 35px;
  }
`;

export const FileUpdateIcon = styled.img`
  height: 24px;
  object-fit: contain;
  cursor: pointer;
`;

// const ActionIcon = styled.button`
//   margin-left: 1.5em;
//   background-color: ${COLORS.PRIMARY_WHITE};
//   border: ${(outlined) => (outlined ? `1px solid ${COLORS.BTN_GREEN}` : 0)};
//   width: 2em;
//   height: 2em;
//   border-radius: 5px;
//   font-size: 1em;
//   text-align: center;
//   outline: none;

//   &:hover {
//     cursor: pointer;
//   }
// `;
