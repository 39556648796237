import React, { useState, useEffect } from "react";
import { Center } from "../../../styles/component/style";
import { SecondaryCTAButton } from "../../common/Buttons";
import {
  WelcomeWrapper,
  HeaderWrapper,
  Header,
  Text,
  ContentWrapper,
  VerticalWrap,
  SubHead,
  VideoCard
} from "./Styles";
import * as ScreenHeader from "../../internal/Header/Header";
import { navigate } from "@reach/router";
import VideoBox from "../../common/VideoBox";
import AboutTermsCard from "../../common/AboutTermsCard";
import SystemService from "../../../services/SystemService";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import { getErrorMessage } from "../../../helpers/functions";
import labels from "../../../helpers/labels.json";

const Index = () => {
  const [systemConfig, setSystemConfig] = useState({});
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    async function getSystemConfig() {
      setLoader({ state: true, message: "Please wait. Loading..." });
      try {
        const response = await SystemService.getConfig();
        setSystemConfig(response.systemConfig);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
    getSystemConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScreenHeader.Header>
      <Center>
        <WelcomeWrapper>
          <HeaderWrapper>
            <Header>{labels.welcome_user}</Header>
            <Text>{labels.welcome_user_details}</Text>
          </HeaderWrapper>

          <ContentWrapper>
            <VerticalWrap>
              {systemConfig?.tutorialUrl?.length ? systemConfig?.tutorialUrl?.split(",")?.map((tutorialUrl, index) => (
                <VideoCard key={index}>
                  <SubHead>Tutorial Video {index + 1}</SubHead>
                  <VideoBox src={tutorialUrl} />
                </VideoCard>
              )) : null}
            </VerticalWrap>
            <AboutTermsCard
              terms={systemConfig?.terminology}
              heading={labels.terms_heading}
            />
          </ContentWrapper>
          <SecondaryCTAButton
            onClick={() => navigate("/view/cases")}
            style={{ marginTop: "24px" }}
          >
            {labels.skip_this_step}
          </SecondaryCTAButton>
        </WelcomeWrapper>
      </Center>
    </ScreenHeader.Header>
  );
};

export default Index;
