import React, { useState } from "react";
import {
  CaseManagerCard,
  CaseManagerTitle,
  Flex,
  CaseManagerProfile,
  CaseManagerName,
  Column,
} from "../styles";
import AddIcon from "@material-ui/icons/Add";
import DrawerModal from "../../DrawerModal/DrawerModal";

export default function CaseManager({
  caseManager,
  title = "IIAC Counsel",
  addMediator,
  disabled = false,
}) {
  const [modal, setModal] = useState(false);
  return (
    <CaseManagerCard disabled={disabled}>
      <Column>
        <CaseManagerTitle>
          {caseManager?.presidingArbitrator === "yes"
            ? "Presiding Arbitrator"
            : title}
        </CaseManagerTitle>
        <Flex className="flex-1">
          {caseManager?.name ? (
            <>
              <CaseManagerProfile
                src={
                  caseManager?.avatarUrl
                    ? caseManager?.avatarUrl
                    : require("../../../../assets/images/userPlaceholder.svg")
                }
              />
              <CaseManagerName onClick={(e) => setModal(true)}>
                {caseManager?.name}
              </CaseManagerName>
            </>
          ) : (
            <div className="add_case_casemanager flex flex-1">
              Add Arbitrator
            </div>
          )}
        </Flex>
      </Column>
      {caseManager?.name ? null : (
        <div className="add_circle" onClick={() => addMediator()}>
          <AddIcon className="add_icon" />
        </div>
      )}
      <DrawerModal
        {...{ modal, setModal }}
        id={caseManager?.id}
        type={title}
        details={caseManager}
      />
    </CaseManagerCard>
  );
}
