import React from "react";
import styled from "styled-components";
import { Images } from "../../../../assets/images";
import { PrimaryCTAButton } from "../../../common/Buttons";
import theme from "../../../../assets/theme";
import useLoader from "../../../../hooks/useLoader";
import CaseService from "../../../../services/CaseService";
import { getErrorMessage } from "../../../../helpers/functions";
import { useSnackbar } from "notistack";

const AgreementDocCard = ({
  type = "Download",
  id,
  status,
  isRespondent,
  hasAgreement,
}) => {
  const { setLoader } = useLoader();

  const { enqueueSnackbar } = useSnackbar();

  const downloadAgreement = async (id) => {
    setLoader({ state: true, message: "Fetching case details..." });
    try {
      const agreementUrl = await CaseService.downloadCaseAgreement(id);
      if (agreementUrl?.data[0]?.url?.url || agreementUrl?.data[0]?.url) {
        window.open(
          agreementUrl?.data[0]?.url?.url || agreementUrl?.data[0]?.url,
          "Download",
        );
      } else {
        enqueueSnackbar("IIAC Counsel has to upload the Agreement", {
          variant: "error",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  return (
    <DocumentContainerCard disableText={!hasAgreement}>
      <div className="file-container">
        <img src={Images.filefloder} alt="fileFoleder" />
        <div>
          {hasAgreement
            ? `Your Final Award Summary for Case ID: ${id} is available to download. Please click on download button.`
            : "Your Final Award preparation is under progress, please check back after sometime."}
        </div>
      </div>
      <div className="btn-container">
        <PrimaryCTAButton
          onClick={() => downloadAgreement(id)}
          disabled={!hasAgreement}
        >
          {type}
        </PrimaryCTAButton>
      </div>
    </DocumentContainerCard>
  );
};

const DocumentContainerCard = styled.div`
  border-radius: 7px;
  width: 100%;
  border: solid 0.5px #e1e3ee;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 20px;
  opacity: ${(props) => (props.disableText ? "0.5" : "1")};
  & .file-container {
    display: flex;
    font-family: ${theme.fonts.primaryFontSemiBold};
    align-items: center;
    flex: 1;
    font-size: 14px;
    color: #293461;
    & div {
      margin-left: 15px;
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
  }
  & .btn-container {
    min-width: 175px;
  }
`;

export default AgreementDocCard;
