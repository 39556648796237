import React from "react";
import { Header } from "../../internal/Header/Header";

const Index = ({ children, hideSidebar }) => {
  return (
    <Header
      showSidebar={!hideSidebar}
      selectedItem="my_cases"
      selectedSubItem="arbitration"
    >
      {children}
    </Header>
  );
};

export default Index;
