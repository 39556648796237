import React, { useEffect, useState } from "react";
import ActionBar from "../../common/ActionBar";
import styled from "styled-components";
import { useLocation } from "@reach/router";
import CasesTable from "../../common/CasesTable/index";
import _ from "lodash";

const Index = () => {
  const location = useLocation();
  const [caseType, setCaseType] = useState("");
  const searchParams = new URLSearchParams(location.search);
  const actions = [];

  useEffect(() => {
    let typeCase = searchParams.get("caseType");
    if (searchParams.get("caseType")) {
      setCaseType(typeCase);
    } else {
      setCaseType("arbitration");
    }
  }, [searchParams]);

  return (
    <div>
      <ActionBar
        {...{ actions }}
        breadcrumbs={["My Cases", _.startCase(caseType)]}
      />
      <Padding>
        {caseType && (
          <CasesTable
            status={searchParams.get("status")}
            caseType={caseType}
            currentCaseType={caseType}
          />
        )}
      </Padding>
    </div>
  );
};

export default Index;

const Padding = styled.div`
  padding-top: 30px;
  padding-bottom: 50px;
  padding-left: 35px;
  padding-right: 53px;
`;
